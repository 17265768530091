//fonts

$rosario: 'Rosario', sans-serif;
$oswald: 'Oswald', sans-serif;

//colors

$white: #FFFFFF;
$black: #262626;

$nia-pink: #FF7383;
$nia-blue-light: #00BDD7;
$nia-blue-dark: #1464AB;

//layout values

$header-search-height: 8rem;